// In src/pages/Page.js
import React, { useEffect, useState,Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { client } from '../prismic-configuration'
import { Banner, TextImage, ImageText, Header, ThreeColumnFeature, IconTwoColumns, Heading, ContactForm } from '../components'

const Page = ({ match }) => {
  const [doc, setDocData] = useState(null)
  const [core, setCoreData] = useState(null)
  const uid = match.params.uid
  const [sticky, setSticky] = useState("")

  let dataSet =[]

  // Get the page document from Prismic
  useEffect(() => {
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 60){
        setSticky("sticky")
      } else {
        setSticky("")
      }
    })
    const fetchData = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getByUID('section', uid)
        // console.log(uid)
      if (result) {
        console.log('doc',result)
        // We use the State hook to save the document
        return setDocData(result)
      } else {
        // Otherwise show an error message
        console.warn('Page document not found. Make sure it exists in your Prismic repository')
      }
    }
    fetchData()
    const fetchCore = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getByUID('core', 'core')
        // console.log(uid)
      if (result) {
        // console.log('core',result)
        // We use the State hook to save the document
        return setCoreData(result)
      } else {
        // Otherwise show an error message
        console.warn('Page document not found. Make sure it exists in your Prismic repository')
      }
    }
    fetchCore()
  }, [uid]) // Skip the Effect hook if the UID hasn't changed

  if (doc && core) {
    doc.data.body.map((module, index) => { 
        switch(module.slice_type) {
          case 'banner':
            dataSet[index] = <Banner data={module} key={index}/>
          break;
          case 'text_image':
            dataSet[index] = <TextImage data={module} key={index}/>
          break;
          case 'image_text':
            dataSet[index] = <ImageText data={module} key={index}/>
          break;
          case 'three_column_feature':
            dataSet[index] = <ThreeColumnFeature data={module} key={index}/>
          break;
          case 'icon_two_columns':
            dataSet[index] = <IconTwoColumns data={module} key={index}/>
          break;
          case 'heading':
            dataSet[index] = <Heading data={module} key={index}/>
          break;
          case 'contact_form':
            dataSet[index] = <ContactForm data={module} key={index}/>
          break;
          default: 
        }
        return null;
    });
    return (
      <Fragment>
        <Helmet>
          <meta property="og:title" content={doc.data.seo_title}/>
          <meta property="og:description" content={doc.data.seo_description} />
          <meta property="og:image" content="https://demandlytix.com/images/demandlytix-logo.jpg" />
          <meta property="og:url" content={'https://demandlytix.com/' + doc.data.uid} />
          <meta name="twitter:card" content="https://demandlytix.com/images/demandlytix-logo.jpg" />
          <meta property="og:site_name" content={doc.data.seo_title}/>
          <meta name="twitter:image:alt" content={doc.data.seo_description}/>
          <title>{'Demandlytix | '+doc.data.seo_title}</title>
          <body></body>
        </Helmet>
        <Header data={core.data.body[0]} stickyState={sticky}/>
          {dataSet.map((module, index) => {
              return module;
          })}
      </Fragment>
    )
        }
    else {
      return (
        null
      )
    }
}

export default Page