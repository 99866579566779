import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const TextImage = (props) => {
        // console.log(props)

        return (
            <div className="text-image">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="content">
                                <RichText render={props.data.primary.left_column} linkResolver={linkResolver} /> 
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image">
                                <img src={props.data.primary.image.url} alt={props.data.primary.image.alt}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default TextImage