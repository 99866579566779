import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const Heading = (props) => {
    // console.log(props)
    return (
        <div className={"heading "+props.data.primary.background} id={props.data.primary.page_anchor}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <RichText render={props.data.primary.heading} linkResolver={linkResolver} /> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Heading