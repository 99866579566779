import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';
import { TextAnimate } from './index'

const Banner = (props) => {
        // console.log(props)
        return (
            <div id={props.data.primary.link_destination} className="banner" style={{backgroundImage: 'url('+props.data.primary.background.url+')'}}>
                <div className="wrapper">
                    <div className="row align-bottom">
                        <div className="col-md-10">
                            <RichText render={props.data.primary.heading} linkResolver={linkResolver} /> 
                            <TextAnimate data={props.data.items} />
                        </div>
                    </div>
                </div>
            </div>
            );
}

export default Banner;