import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const IconTwoColumns = (props) => {
        // console.log(props)

        return (
            <div className={"icon-two-columns "+props.data.primary.background}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-lg-4">
                            <img src={props.data.primary.icon.url} alt={props.data.primary.icon.alt}/>
                        </div>
                        <div className="col-lg-4">
                            <RichText render={props.data.primary.column_one} linkResolver={linkResolver} /> 
                        </div>
                        <div className="col-lg-4">
                            <RichText render={props.data.primary.column_two} linkResolver={linkResolver} /> 
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default IconTwoColumns